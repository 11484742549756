import React, { Suspense, useEffect } from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import LoadingFallback from "./components/LoadingFallback";
import ContactButtons from "./components/ContactButtons";

const Loading = React.lazy(() => import("./pages/Loading"));
const Error = React.lazy(() => import("./pages/Error"));
const Home = React.lazy(() => import("./pages/Home"));

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;

  useEffect(() => {
    Aos.init();
  }, []);

  return (
      <IntlProvider messages={messages} locale={locale}>
        <div className="App">
          <Suspense fallback={<LoadingFallback />}>
            <Router>
              <Routes>
                <Route path="/" index element={<Loading />} />
                <Route>
                {/* <Route element={<SharedLayout />}> */}
                  <Route index path="/home" element={<Home />} />
                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
              <ContactButtons />

            </Router>
          </Suspense>
        </div>
      </IntlProvider>
  );
}

export default App;
