import { configureStore } from "@reduxjs/toolkit";
import LanguageReducer from "./features/LanguageSlice";
import NavbarReducer from "./features/NavbarSlice";
import HomeReducer from "./features/HomeSlice";

const store = configureStore({
  reducer: {
    language: LanguageReducer,
    nav: NavbarReducer,
    home: HomeReducer,
  },
});

export default store;
